import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col } from "react-bootstrap"
import Footer from "../components/footer"
import { graphql } from 'gatsby'
import {isMobile} from 'react-device-detect';

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentLoaded: true,
      isMobile: false,
    };
  }

  componentDidMount() {
    this.setState({ isMobile: isMobile })
  }

  render() {
    return(
      <Layout pageInfo={{ pageName: "404" }}>
        <SEO title="404: Not found" />
        <Row className="mx-auto align-items-center" style={{minHeight:`100vh`}}>
          <Col md={3} sm={12} lg={3} xl={3} xs={12}></Col>
          <Col md={6} sm={12} lg={6} xl={6} xs={12}>
            <h4 className="text-center">Got Lost?</h4>
            <p className="text-center"><small>
            The 404 (Not Found) status code indicates that the origin server did
   not find a current representation for the target resource or is not
   willing to disclose that one exists.  A 404 status code does not
   indicate whether this lack of representation is temporary or
   permanent; the 410 (Gone) status code is preferred over 404 if the
   origin server knows, presumably through some configurable means, that
   the condition is likely to be permanent.
            </small>
            </p>
            <p className="text-center"><small>
              - RFC7231 Section 6.5.4
            </small>
            </p>
          </Col>
          <Col md={3} sm={12} lg={3} xl={3} xs={12}></Col>

        </Row>
        <Footer
          isMobile={this.state.isMobile}
          menus={this.props.data.allContentfulMenu.edges}
          siteInfo={this.props.data.allContentfulSiteInfo.nodes[0]}
          socials={this.props.data.allContentfulSocials.nodes}
        />
      </Layout>
    )
  }
}

export default NotFoundPage

export const Query = graphql`
query {
  allContentfulMenu(sort: {fields: order, order: ASC}) {
    edges {
      node {
        name
        link
      }
    }
  }
  allContentfulSiteInfo {
    nodes {
      id
      copyrightText
      addressLink
      address {
        address
      }
      domain
      email
      fullName
      logoLarge {
        gatsbyImageData(formats: WEBP)
      }
      logoSmall {
        gatsbyImageData(formats: WEBP)
      }
      phone
      shortName
    }
  }
  allContentfulSocials {
    nodes {
      link
      name
    }
  }
}
`
